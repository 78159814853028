import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import CounterOne from "./counters/CounterOne";
import Testimonial from "./Testimonial";
import BrandTwo from "./BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import Footer from "../component/footer/FooterTwo";
import "./styles/InvestorsStyles.css"

import Lottie from "react-lottie";
import investorsAnimation from "../../public/assets/images/lotties/investorsanimation.json";


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: investorsAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    // className: "lottie-animation-index"
  },
};

class Investors extends Component {
  render() {
    let title = "Investors",
      description =
        "From 2020 we raised 4 million USD from Angel Investors. We are currently raising our Series A round of 10 million USD to expand our team and our product offering.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="0workers" />
        {/* Start Header Area  */}
        <Header
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-black"
        />

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    {/* <img
                      className="w-100"
                      src="/assets/images/about/about-3.jpg"
                      alt="About Images"
                    /> */}
                    <Lottie options={defaultOptions} />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Who are we</h3>
                          <p>
                            We are a team of passionate developers and engineers
                            who are building the next generation of computer
                            vision applications.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Who are our Investors</h3>
                          <p>
                            Our selected groupd of angels is composed of
                            experienced entrepreneurs who are into the idea a
                            more controlled future.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Company</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Finding Us Area  */}
        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h4 className="theme-gradient">Join Our Team.</h4>
                <p>
                  We are always looking for talented people to join our team. If
                  you are passionate about AI and want to work in a fast-paced
                  environment, send us your CV.
                </p>
                <a className="rn-btn btn-white" href="/contact">
                  Get Started
                </a>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img
                  src="/assets/images/about/office-ba-0workers.jpeg"
                  alt="BA office 0workers"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Team Area  */}
        {/* <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Our Team</h2>
                  <p>
                    Our team is composed of passionate developers and engineers
                    who are building the next generation of computer vision
                    applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-01.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Facundo Columbich</h4>
                    <p className="designation">COO</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-02.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Francisco José D'Amico</h4>
                    <p className="designation">CEO</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.linkedin.com/in/damicofj/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-03.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Franco Sarmiento</h4>
                    <p className="designation">Sr. Engineer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="rn-testimonial-area bg_color--5 ptb-200">
          <div className="container">
            <Testimonial />
          </div>
        </div> */}
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Investors;
