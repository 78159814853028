const BlogContent = [
    {
        images: 'supermercado',
        title: ' How Supermercados La Anonima reduced shoplifting by 88%',
        category: 'Development',
    },
    {
        images: 'municipio',
        title: ' How municipality of Formosa increased the income from fines by 220%',
        category: 'Development'
    },
    {
        images: 'fabrica',
        title: ' Manufactory warehouse optimization with 0workers',
        category: 'Development'
    },
    {
        images: '04',
        title: ' Getting tickets to the big show',
        category: 'Development'
    },
    {
        images: '01',
        title: 'A big ticket gone to be an interesting ',
        category: 'Management'
    },
    {
        images: '02',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
]

export default BlogContent;