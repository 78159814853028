import React ,{ Component }from "react";
import { FiLayers , FiUsers , FiCpu, FiList, FiActivity, FiCrop } from "react-icons/fi";
import './ServiceListStyle.css'

const ServiceList = [
    {
        icon: <FiCrop />,
        title: 'Object Detector',
        description: 'A wide range of detectors for use cases such as intruder detection, lane invasion, etc.'
    },
    {
        icon: <FiLayers />,
        title: 'Action Recognizer',
        description: 'Algorithms to recognize human actions in videos.'
    },
    {
        icon: <FiActivity />,
        title: 'Activity Tracker',
        description: 'Different algorithms to understand and gain insight from human activities.'
    },
    { 
        icon: <FiList />,
        title: 'Event Counter',
        description: 'Event counters for different use cases such as people counting, vehicle counting, etc.'
    },
    {
        icon: <FiUsers />,
        title: 'Anomaly Detector',
        description: 'Detect anomalies on time in videos and images programmatically.'
    },
    { 
        icon: <FiCpu />,
        title: 'Custom Model',
        description: 'Custom models for your specific use case and dataset with the best accuracy.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/contact">
                                <div className="service service__style--2 card-color-white">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
