import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./tabStyle.css";

const smartCity = [
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/sense.webp",
    category: "Smart City: Home Security",
    title: "Suspicious Activity Detector",
    link: "/contact",
  },
  {
    image: "08",
    bigImage: "/assets/images/portfolio/big/vehicletheft.webp",
    category: "Smart City: Home Security",
    title: "Vehicle Theft Detector",
    link: "/contact",
  },
  {
    image: "01",
    bigImage: "/assets/images/portfolio/big/littering.png",
    category: "Smart City: Traffic Management",
    title: "Illegal Littering Action Detector",
    link: "/contact",
  },
  {
    image: "01",
    bigImage: "/assets/images/portfolio/big/cellphoneuse.jpeg",
    category: "Smart City: Traffic Management",
    title: "Cell Phone Use Detector",
    link: "/contact",
  },
  {
    image: "02",
    bigImage: "/assets/images/portfolio/big/helmetuse.png",
    category: "Smart City: Traffic Management",
    title: "Helmet Use Detector",
    link: "/contact",
  },
  {
    image: "03",
    bigImage: "/assets/images/portfolio/big/seatbeltuse.jpg",
    category: "Smart City: Traffic Management",
    title: "Seat Belt Use Detector",
    link: "/contact",
  },
  {
    image: "04",
    bigImage: "/assets/images/portfolio/big/damagedvehicle.png",
    category: "Smart City: Traffic Management",
    title: "Damaged Vehicle Detector",
    link: "/contact",
  },
  {
    image: "05",
    bigImage: "/assets/images/portfolio/big/collision.jpeg",
    category: "Smart City: Traffic Management",
    title: "Collision Occurence Detector",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/illegalparking.png",
    category: "Smart City: Traffic Management",
    title: "Illegal Parking Detector",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/vehiclefire.jpeg",
    category: "Smart City: Traffic Management",
    title: "Vehicle on Fire Detector",
    link: "/contact",
  },
  {
    image: "07",
    bigImage: "/assets/images/portfolio/big/robbery.png",
    category: "Smart City: Social Security",
    title: "Robbery Detector",
    link: "/contact",
  },
  {
    image: "09",
    bigImage: "/assets/images/portfolio/big/weapon.png",
    category: "Smart City: Social Security",
    title: "Weapon Detector",
    link: "/contact",
  },
  {
    image: "10",
    bigImage: "/assets/images/portfolio/big/burglar.png",
    category: "Smart City: Social Security",
    title: "Burglary Detector",
    link: "/contact",
  },
  {
    image: "11",
    bigImage: "/assets/images/portfolio/big/poopdetector.jpeg",
    category: "Smart City: Social Security",
    title: "Dog Poop Detector (not picking up)",
    link: "/contact",
  },
  {
    image: "12",
    bigImage: "/assets/images/portfolio/big/smokerdetector.png",
    category: "Smart City: Social Security",
    title: "Smoker Detector",
    link: "/contact",
  },
  {
    image: "12",
    bigImage: "/assets/images/portfolio/big/falldetector.png",
    category: "Smart City: Social Security",
    title: "Health Hazard Detector (Heart Stroke, Fall, etc)",
    link: "/contact",
  },
  {
    image: "12",
    bigImage: "/assets/images/portfolio/big/drugalcohol.png",
    category: "Smart City: Social Security",
    title: "Alcoholic Detector",
    link: "/contact",
  },
  {
    image: "11",
    bigImage: "/assets/images/portfolio/big/firehazardhouse.jpeg",
    category: "Smart City: Social Security",
    title: "Fire Hazard Detector",
    link: "/contact",
  },
  {
    image: "09",
    bigImage: "/assets/images/portfolio/big/sensenerv.webp",
    category: "Smart City: Airport Security",
    title: "Nervous Passenger Detector",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/abandonedbag.png",
    category: "Smart City: Airport Security",
    title: "Abandoned Luggage Detector",
    link: "/contact",
  },
];

const Industrial = [
  {
    image: "04",
    bigImage: "/assets/images/portfolio/big/safetyvest.png",
    category: "Industrial: Construction/Industrial Safety",
    title: "Safety Helmet and Vest Detector",
    link: "/contact",
  },
  {
    image: "05",
    bigImage: "/assets/images/portfolio/big/presenteeism.jpeg",
    category: "Industrial: Construction/Industrial Safety",
    title: "Presenteeism Tracker",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/officecellphoneuse.png",
    category: "Industrial: Construction/Industrial Safety",
    title: "Cell Phone Use during work hours Tracker",
    link: "/contact",
  },
  {
    image: "12",
    bigImage: "/assets/images/portfolio/big/manufacturing.png",
    category: "Industrial: Construction/Industrial Safety",
    title: "Manufacturing Defect Inspection Detector (e.g. bad welding)",
    link: "/contact",
  },
  {
    image: "03",
    bigImage: "/assets/images/portfolio/big/productivity.png",
    category: "Industrial: Office Work",
    title: "Productivity Tracker",
    link: "/contact",
  },
];

const Agriculture = [
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/crophealth.jpeg",
    category: "Agriculture",
    title: "Crop Health Detector (e.g. disease, pest, etc)",
    link: "/contact",
  },
  {
    image: "01",
    bigImage: "/assets/images/portfolio/big/farmintruder.jpeg",
    category: "Agriculture",
    title: "Intruder Detector",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/cattlecounter.png",
    category: "Agriculture",
    title: "Cattle Counter",
    link: "/contact",
  },
  {
    image: "08",
    bigImage: "/assets/images/portfolio/big/fireonland.png",
    category: "Agriculture",
    title: "Fire Hazard Detector",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/classifyfruit.jpeg",
    category: "Agriculture",
    title: "Fruit Clasifier (e.g. apple, orange, etc)",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/farmproductivity.jpeg",
    category: "Agriculture",
    title: "Productivity Tracker",
    link: "/contact",
  },
];

const Education = [
  {
    image: "11",
    bigImage: "/assets/images/portfolio/big/attentionschool.jpeg",
    category: "Education",
    title: "Student Attention Tracker",
    link: "/contact",
  },
];

const Commerce = [
  {
    image: "08",
    bigImage: "/assets/images/portfolio/big/shoplifting.jpeg",
    category: "Commerce: Retail",
    title: "Shoplifting Detector",
    link: "/contact",
  },
  {
    image: "07",
    bigImage: "/assets/images/portfolio/big/heatmap-go.png",
    category: "Commerce: Retail",
    title: "Customer Tracker",
    link: "/contact",
  },
  {
    image: "06",
    bigImage: "/assets/images/portfolio/big/fullautomated.png",
    category: "Commerce: Retail",
    title: "100% Automated Checkout System",
    link: "/contact",
  },
];

const TabCustom = [
  {
    image: "01",
    bigImage: "/assets/images/portfolio/big/custom.webp",
    category: "Custom Solution",
    title: "Get a quote for your custom solution",
    link: "/contact",
  },
];

const AllIndustries = [
  ...Education,
  ...Agriculture,
  ...Commerce,
  ...smartCity,
  ...Industrial,
  ...TabCustom,
];

class TabStyleThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allindustries: 0,
      smartcity: 0,
      industrial: 0,
      agriculture: 0,
      security: 0,
      education: 0,
      commerce: 0,
      tabcustom: 0,
      isOpen: false,
    };
  }
  render() {
    const { column } = this.props;
    const {
      allindustries,
      smartcity,
      industrial,
      agriculture,
      security,
      education,
      commerce,
      tabcustom,
      isOpen,
    } = this.state;
    return (
      <div>
        <Tabs>
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="tablist-inner" style={{ color: "pink" }}>
                <TabList className="pv-tab-button text-center mt--0">
                  <Tab>
                    {/* tab1 */}
                    <span style={{ color: "purple" }}>All Industries</span>
                  </Tab>
                  <Tab>
                    {/* tab4 */}
                    <span style={{ color: "purple" }}>Agriculture</span>
                  </Tab>
                  <Tab>
                    {/* tab7 */}
                    <span style={{ color: "purple" }}>Commerce</span>
                  </Tab>
                  <Tab>
                    {/* tab6 */}
                    <span style={{ color: "purple" }}>Education</span>
                  </Tab>
                  <Tab>
                    {/* tab3 */}
                    <span style={{ color: "purple" }}>Industrial</span>
                  </Tab>
                  <Tab>
                    {/* tab2 */}
                    <span style={{ color: "purple" }}>Smart City</span>
                  </Tab>
                  <Tab>
                    {/* tabCustom */}
                    <span style={{ color: "purple" }}>Custom Solution</span>
                  </Tab>
                </TabList>
              </div>
            </div>
          </div>

          {/* tab 1 */}
          <TabPanel className="row row--35">
            {AllIndustries.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={AllIndustries[allindustries].bigImage}
                    nextSrc={
                      AllIndustries[(allindustries + 1) % AllIndustries.length]
                    }
                    prevSrc={
                      AllIndustries[
                        (allindustries + AllIndustries.length - 1) %
                          AllIndustries.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        allindustries:
                          (allindustries + AllIndustries.length - 1) %
                          AllIndustries.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        allindustries:
                          (allindustries + 1) % AllIndustries.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href={`${value.link}`}>
                          <img
                            src={`${value.bigImage}`}
                            alt="Portfolio Images"
                            style={{ height: "250px", objectFit: "cover" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <a href={`${value.link}`}>
                          <p>{value.category}</p>
                        </a>
                        <h4>
                          <a href={`${value.link}`}>{value.title}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          {/* tab 4 */}
          <TabPanel className="row row--35">
            {Agriculture.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={Agriculture[agriculture].bigImage}
                    nextSrc={
                      Agriculture[(agriculture + 1) % Agriculture.length]
                    }
                    prevSrc={
                      Agriculture[
                        (agriculture + Agriculture.length - 1) %
                          Agriculture.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        agriculture:
                          (agriculture + Agriculture.length - 1) %
                          Agriculture.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        agriculture: (agriculture + 1) % Agriculture.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href={`${value.link}`}>
                          <img
                            src={`${value.bigImage}`}
                            alt="Portfolio Images"
                            style={{ height: "250px", objectFit: "cover" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <a href={`${value.link}`}>
                          <p>{value.category}</p>
                        </a>
                        <h4>
                          <a href={`${value.link}`}>{value.title}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          {/* tab 7 */}
          <TabPanel className="row row--35">
            {Commerce.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={Commerce[commerce].bigImage}
                    nextSrc={Commerce[(commerce + 1) % Commerce.length]}
                    prevSrc={
                      Commerce[
                        (commerce + Commerce.length - 1) % Commerce.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        commerce:
                          (commerce + Commerce.length - 1) % Commerce.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        commerce: (commerce + 1) % Commerce.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href={`${value.link}`}>
                          <img
                            src={`${value.bigImage}`}
                            alt="Portfolio Images"
                            style={{ height: "250px", objectFit: "cover" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <a href={`${value.link}`}>
                          <p>{value.category}</p>
                        </a>
                        <h4>
                          <a href={`${value.link}`}>{value.title}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          {/* tab 6 */}
          <TabPanel className="row row--35">
            {Education.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={Education[education].bigImage}
                    nextSrc={Education[(education + 1) % Education.length]}
                    prevSrc={
                      Education[
                        (education + Education.length - 1) % Education.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        education:
                          (education + Education.length - 1) % Education.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        education: (education + 1) % Education.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href={`${value.link}`}>
                          <img
                            src={`${value.bigImage}`}
                            alt="Portfolio Images"
                            style={{ height: "250px", objectFit: "cover" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <a href={`${value.link}`}>
                          <p>{value.category}</p>
                        </a>
                        <h4>
                          <a href={`${value.link}`}>{value.title}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          {/* tab 3 */}
          <TabPanel className="row row--35">
            {Industrial.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={Industrial[industrial].bigImage}
                    nextSrc={Industrial[(industrial + 1) % Industrial.length]}
                    prevSrc={
                      Industrial[
                        (industrial + Industrial.length - 1) % Industrial.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        industrial:
                          (industrial + Industrial.length - 1) %
                          Industrial.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        industrial: (industrial + 1) % Industrial.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href={`${value.link}`}>
                          <img
                            src={`${value.bigImage}`}
                            alt="Portfolio Images"
                            style={{ height: "250px", objectFit: "cover" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <a href={`${value.link}`}>
                          <p>{value.category}</p>
                        </a>
                        <h4>
                          <a href={`${value.link}`}>{value.title}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          {/* tab 2 */}
          <TabPanel className="row row--35">
            {smartCity.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={smartCity[smartcity].bigImage}
                    nextSrc={smartCity[(smartcity + 1) % smartCity.length]}
                    prevSrc={
                      smartCity[
                        (smartcity + smartCity.length - 1) % smartCity.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        smartcity:
                          (smartcity + smartCity.length - 1) % smartCity.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        smartcity: (smartcity + 1) % smartCity.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href={`${value.link}`}>
                          <img
                            src={`${value.bigImage}`}
                            alt="Portfolio Images"
                            style={{ height: "250px", objectFit: "cover" }}
                            className="neon-image"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <a href={`${value.link}`}>
                          <p>{value.category}</p>
                        </a>
                        <h4>
                          <a href={`${value.link}`}>{value.title}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          {/* tab custom */}
          <TabPanel className="row row--35">
            {TabCustom.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={TabCustom[tabcustom].bigImage}
                    nextSrc={TabCustom[(tabcustom + 1) % TabCustom.length]}
                    prevSrc={
                      TabCustom[
                        (tabcustom + TabCustom.length - 1) % TabCustom.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        tabcustom:
                          (tabcustom + TabCustom.length - 1) % TabCustom.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        tabcustom: (tabcustom + 1) % TabCustom.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href={`${value.link}`}>
                          <img
                            src={`${value.bigImage}`}
                            alt="Portfolio Images"
                            style={{ height: "250px", objectFit: "cover" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <a href={`${value.link}`}>
                          <p>{value.category}</p>
                        </a>
                        <h4>
                          <a href={`${value.link}`}>{value.title}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default TabStyleThree;
