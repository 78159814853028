import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We spent so much time and money on generating synthetic data without results to justify our spending. 0workers stepped in and provided impactful training methods that improved our system performance.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Davided Lima </span> - CTO, AIKIDO AI.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Our customers are very heterogeneous. We have customers that provide us with thousands of documents a week, while others might require months for a fraction of the same volume. Therefore, finding a reliable and flexible partner for creating high-quality algorithms fast was key for us..</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Hugo Sanchez </span> - Head of Operations, SAP EUROPA.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>There was one particular case where our program average precision was 76%. We improved average precision on that class to 87%+ after just one iteration of development with 0workers.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Mohammed Salama </span> - CO-Founder, Cellereye.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Many of our customers don't have the infrastructure in place to produce high-quality algorithms. We knew that finding a way to improve the quality of the product imagery on our large marketplace was key to improving conversion rates, but we didn't know how to achieve this at 0workers.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Samir Gowru </span> - Head of Design, Rosetta Stone.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p> Our programs improved by a 10x with the help of 0workers. 

                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Shamal Patil </span> - Sr. Developer, SenseTem.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We send 0workers 500-1000 requests per day to extract text from images, and 0workers reliably gets us results within miliseconds.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Julie Milicci </span> - CEO, Juke.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>ML models only deliver the highest accuracy when they can handle edge cases that might be challenging, uncommon, or even dangerous. We depend on the support of 0workers for this.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Jack Ma </span> - Head of Platform, NERO INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Working with 0workers has also allowed us to iterate on our taxonomy very easily. The ability to quickly process new data on new taxonomies has been very smooth and has led to better app improvements.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Andrea Nuora </span> - Sr. ML Engineer, Vista Energy.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-5.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-6.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-7.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-8.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;