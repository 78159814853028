import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/sap.png" alt="SAP"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/celler.png" alt="Celler"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/rosetta.png" alt="Rosetta"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/sense.webp" alt="Sense"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/aikido.webp" alt="Aikido"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/vista.png" alt="Vista"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/juke.png" alt="Juke"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/nero.png" alt="Nero"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/formosa.png" alt="Formosa"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;