import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import "./ContactFormStyle.css";
import ReCAPTCHA from "react-google-recaptcha";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. We will be in touch within the
      next 12 hours.
    </p>
  );
};

function ContactForm({ props }) {
  const [result, showResult] = useState(false);
  const recaptchaRef = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    if (recaptchaRef.current) {
      const recaptchaValue = recaptchaRef.current.getValue();

      if (!recaptchaValue) {
        console.log("reCAPTCHA not verified");
        return;
      }
    }

    emailjs
      .sendForm(
        "service_emailjs_0worker1",
        "template_ckxeqyt",
        e.target,
        "eVf7yRLZ0v5DtXw2I"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 5000);

  return (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input type="text" name="firstname" placeholder="First Name" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="lastname" placeholder="Last Name" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="company" placeholder="Company Name" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="job" placeholder="Job Title" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Work Email" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Phone Number" required />
      </div>

      <div className="rn-form-group">
        <textarea
          type="text"
          name="message"
          placeholder="Please describe your project in detail (data type, volume, timeline, budget, etc.)"
          style={{ height: "200px" }}
          required
        ></textarea>
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LecDEwoAAAAAD3VH9ywdHGzXZBlkLAqE0EB2FrY"
      />

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}

export default ContactForm;

// import React, { useState } from "react";
// import emailjs from "emailjs-com";
// import "./ContactFormStyle.css";
// import { reCAPTCHA } from "react-google-recaptcha";

// const Result = () => {
//   return (
//     <p className="success-message">
//       Your Message has been successfully sent. We will be in touch within the
//       next 12 hours.
//     </p>
//   );
// };
// function ContactForm({ props }) {
//   const [result, showresult] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_emailjs_0worker1",
//         "template_ckxeqyt",
//         e.target,
//         "eVf7yRLZ0v5DtXw2I"
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//     e.target.reset();
//     showresult(true);
//   };

//   setTimeout(() => {
//     showresult(false);
//   }, 5000);

//   return (
//     <form action="" onSubmit={sendEmail}>
//       <div className="rn-form-group">
//         <input type="text" name="fullname" placeholder="Your Name" required />
//       </div>

//       <div className="rn-form-group">
//         <input type="email" name="email" placeholder="Your Email" required />
//       </div>

//       <div className="rn-form-group">
//         <input type="text" name="phone" placeholder="Phone Number" required />
//       </div>

//       <div className="rn-form-group">
//         <textarea
//           type="text"
//           name="message"
//           placeholder="Your Message"
//           required
//         ></textarea>
//       </div>

//       <reCAPTCHA
//         render="explicit"
//         sitekey={
//           //   process.env.REACT_APP_SITE_KEY ||
//           "6LecDEwoAAAAAD3VH9ywdHGzXZBlkLAqE0EB2FrY"
//         }
//       />

//       <div className="rn-form-group">
//         <button
//           className="rn-button-style--2 btn-solid"
//           type="submit"
//           value="submit"
//           name="submit"
//           id="mc-embedded-subscribe"
//         >
//           Submit Now
//         </button>
//       </div>

//       <div className="rn-form-group">{result ? <Result /> : null}</div>
//     </form>

//   );
// }
// export default ContactForm;
