import React, { Component } from "react";
import ContactForm from "./ContactForm";
import Lottie from "react-lottie";
import contactAnimation from "../../../public/assets/images/lotties/contactanimation-1.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: contactAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class ContactTwo extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Us.</h2>
                <p className="description">
                  Join leading teams accelerating their applications with 0workers. Tell us more about your project and book a 1:1 demo to get you started.
                </p>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                {/* <img src="/assets/images/about/about-6.jpg" alt="trydo"/> */}
                <Lottie options={defaultOptions} height={400} width={400} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
