import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import TabThree from "../elements/tab/TabThree";
import Header from "../component/header/HeaderFour";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";

class ProjectsAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Products" />
        <Header
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-black"
        />

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title">Projects</h2>
                  <p>
                    Our projects are designed to help you build entire computer
                    vision applications faster. You can use for action
                    recognition, image classification, object detection, and
                    more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Designer Portfolio  */}
        <div className="designer-portfolio-area ptb--120 bg_color--1">
          <div className="wrapper plr--70 plr_sm--30 plr_md--30">
            <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
          </div>
        </div>
        {/* End Designer Portfolio  */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default ProjectsAll;
