import React, { Component } from "react";
import Helmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import Footer from "../component/footer/FooterTwo";
import CallAction from "./callaction/CallAction";

class BlogDetails2 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet pageTitle="Products" />
        <Header
          headerPosition="header--static"
          logo="logo.svg"
          color="color-black"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    How municipality of Formosa increased the <br /> income from
                    fines by 220%
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      Oct 1, 2023
                    </li>
                    <li>
                      <FiUser />
                      Francisco José D'Amico
                    </li>
                    <li>
                      <FiMessageCircle />0 Comments
                    </li>
                    <li>
                      <FiHeart />
                      Like
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      Retail industry businesses face the constant threat of
                      theft-related inventory shrinkage, but traditional retail
                      theft prevention methods leave a lot to be desired. In
                      fact, despite the best efforts of retailers, one study
                      indicates that 33% of retail shrinkage is from shoplifting
                      and 33.1% is from employee theft. Now, some retailers are
                      adopting computer vision technology for retail loss
                      prevention. By leveraging AI-enabled cameras and
                      sophisticated computer vision algorithms, retailers are
                      reducing theft-related inventory shrinkage like never
                      before.
                    </p>
                    {/* <div className="thumbnail">
                      <img
                        src="/assets/images/blog/bl-big-01.jpg"
                        alt="Blog Images"
                      />
                    </div> */}
                    <h3 className="title">
                      The challenges of traditional retail theft control
                      strategies
                    </h3>
                    <p className="mt--40">
                      It's important for retailers to use traditional theft
                      control strategies — like human security personnel,
                      security tags, and POS software — but these strategies
                      don't prevent all instances of theft. In fact, shoplifting
                      results in over $15 billion in U.S. retail losses each
                      year, and this doesn't account for the enormous costs of
                      employee theft.
                    </p>
                    <blockquote className="rn-blog-quote">
                      As the Security Chief at Supermercados La Anonima, I can
                      confidently attest to the significant reduction in theft
                      since we adopted advanced computer vision technology. Our
                      commitment to ensuring a secure shopping environment has
                      not only made our stores safer but also transformed the
                      overall shopping experience for our customers. We're proud
                      to continue setting the standard for retail security.
                    </blockquote>
                    <h4 className="title">
                      Despite the use of common theft control strategies,
                      retailers still face the following challenges.
                    </h4>
                    <ul className="list-style">
                      <li>
                        <strong>
                          Stockrooms and supply rooms are vulnerable:
                        </strong>{" "}
                        It's difficult for retailers to track what's happening
                        in restricted areas like stockrooms, offices, and
                        breakrooms. Dishonest customers, sales employees, and
                        cleaning staff sneak into these unattended areas and
                        steal items without being noticed.
                      </li>
                      <li>
                        <strong>Employees are often seasonal:</strong> Most
                        retailers perform background security checks on new
                        employees. However, numerous retail employees are
                        temporary and seasonal, making it difficult to know
                        which ones can be trusted not to steal.
                      </li>
                      <li>
                        <strong>The dangers of “sweethearting”:</strong>{" "}
                        Sweethearting happens when a cashier employee adds
                        additional discounts or purposefully doesn't scan or
                        charge customers for items.
                      </li>
                      <li>
                        <strong>Organized crime:</strong> Instances of organized
                        shoplifting crimes are growing more common. In these
                        instances, a number of shoplifters will into the store
                        and overwhelm the staff by asking for help with
                        different products. While staff members are engaged, the
                        other shoplifters will steal items without being
                        noticed.
                      </li>
                      <li>
                        <strong>Backdoor theft and trash can theft:</strong>{" "}
                        Employees may steal items by placing them in boxes and
                        taking them out of the backdoor of stores. They could
                        also throw the items they want to steal in the trash and
                        recoup them at a later time.
                      </li>
                    </ul>
                    <div className="mt--40">
                      <h3 className="title">
                        Leveraging computer vision to stop retail theft
                      </h3>
                      <p>
                        Computer vision systems for retail theft prevention use
                        a network of AI-enabled cameras, machine learning
                        models, and advanced analytics to detect instances of
                        theft and immediately notify managers to investigate.
                        These solutions operate throughout the day, never get
                        distracted, and monitor all store areas simultaneously.
                        They can run in the cloud for easy scalability or on
                        edge servers for faster processing and maximum data
                        security.
                      </p>
                      <p>
                        0workers computer vision systems for retail loss
                        prevention can provide the following features and more:
                      </p>
                    </div>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src="/assets/images/blog/shoplifting.gif"
                          alt="BLog Images"
                        />
                        <span>
                          0workers Sentinel System - Demonstration on the Real
                          World{" "}
                        </span>
                      </div>
                      <div className="content">
                        <ul className="list-style">
                          <li>
                            <strong>
                              Tracking product locations and customer behavior:
                            </strong>{" "}
                            Computer vision can detect when a customer is about
                            to leave the store without paying for an item. These
                            systems can also trigger alerts to security
                            personnel when a product disappears into a
                            customer's pocket or bag.
                          </li>
                          <li>
                            <strong>
                              Tracking products and boxes in supply rooms:
                            </strong>{" "}
                            Computer vision can monitor and track the status and
                            locations of boxes and products in supply rooms for
                            better theft prevention and organization.
                          </li>
                          <li>
                            <strong>Tracking for organized shoplifting:</strong>{" "}
                            Visual AI can watch for the signs of an organized
                            shoplifting attack by tracking the number of
                            customers in a store and their behaviors at all
                            times.
                          </li>
                          <li>
                            <strong>
                              Detection of known shoplifters and criminals:
                            </strong>{" "}
                            Visual AI can immediately detect the faces of known
                            shoplifters and criminals as soon as they walk into
                            a retail store.
                          </li>
                          <li>
                            <strong>
                              Access control for restricted areas:
                            </strong>{" "}
                            Facial detection can control access to restricted
                            areas like supply rooms. These systems use facial
                            recognition technology that ensures only authorized
                            employees enter restricted areas.
                          </li>
                          <li>
                            <strong>Better checkout security:</strong> Computer
                            vision technology can monitor cash registers to
                            ensure that human cashiers charge customers
                            appropriately for all items. This technology can
                            also detect when customers try to steal items at
                            self-checkout stations.
                          </li>
                          <li>
                            <strong>Backdoor alerts:</strong> AI Vision models
                            can immediately notify managers whenever an employee
                            walks out the backdoor with items or boxes.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <p className="mt--25 mt_sm--5">
                      In addition to these advantages, 0workers platform
                      delivers solutions for analyzing customer behavior and
                      demographics and tracking and monitoring shelf space for
                      out-of-stock items.
                    </p>
                    <div className="video-wrapper position-relative mb--40">
                      <div className="thumbnail">
                        <img
                          src="/assets/images/blog/theft-screenshot.png"
                          alt="Blog Images"
                        />
                      </div>
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="NCFc2BjtZYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className="video-popup position-top-center"
                        onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div>
                    <p className="mb--0">
                      Contact us today to learn more about how 0workers Sentinel
                      can help you study customer behavior, track inventory,
                      prevent retail theft and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        <CallAction />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default BlogDetails2;
